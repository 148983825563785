import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64')
];

export const server_loads = [0,2,13,14];

export const dictionary = {
		"/": [17],
		"/account/integrations": [18,[2]],
		"/account/invoices": [19,[2]],
		"/account/preferences": [20,[2]],
		"/account/profile": [21,[2]],
		"/account/subscription": [22,[2]],
		"/app": [23,[3]],
		"/checkout": [24],
		"/checkout/update-payment": [25],
		"/community/documents/[id]": [26,[4]],
		"/community/notes/[id]": [27,[4]],
		"/configure-integration/bp/setup": [28],
		"/consult": [29],
		"/create-account": [30,[5]],
		"/create-account/details": [31,[5]],
		"/create-account/password": [32,[5]],
		"/customisation/document-personalisation": [33,[6]],
		"/customisation/documents": [34,[6,7]],
		"/customisation/documents/new_document": [36,[6,7]],
		"/customisation/documents/[slug]": [35,[6,7]],
		"/customisation/find-and-replace": [37,[6]],
		"/customisation/general": [38,[6]],
		"/customisation/macros": [39,[6]],
		"/customisation/note-personalisation": [40,[6]],
		"/demo": [41,[8]],
		"/dictate": [42,[9]],
		"/downtime": [~43],
		"/form/[code]": [44],
		"/help": [45,[10]],
		"/history": [46,[11]],
		"/history/documents/[slug]": [~47,[11,12]],
		"/logging-in": [48],
		"/login": [49],
		"/login/mfa": [50],
		"/organisation/manage": [51,[13]],
		"/organisation/preferences": [52,[13]],
		"/organisation/subscription": [53,[13]],
		"/organisation/usage": [54,[13]],
		"/personalisation/documents/legacy/[slug]": [56],
		"/personalisation/documents/[slug]": [55],
		"/personalisation/notes/[slug]": [57],
		"/preconsult/[code]": [58],
		"/security/mfa": [59,[14]],
		"/signin": [60,[15]],
		"/signin/mfa": [61,[15]],
		"/signin/password": [62,[15]],
		"/signup": [63],
		"/update-password": [64,[16]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';